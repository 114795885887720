import '../styles/css/listing.css';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';

export default function CardSwiper({ images, id, height, width, fullUrl }) {

    return (
        <>
       
            <Carousel className="carousel slide">
           
                {
                        fullUrl ? images !== undefined ? images.map((o, index) => (
                            <div key={o.fullUrl} className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                <img id={o.fullUrl} style={{ height: height, width: width }} className='property-img ' alt='thumb' src={`${o.fullUrl}`} ></img>
                            </div>
                        )) :
                            <div>
                            </div> : images !== undefined ? images.map((o, index) => (
                                <div key={o.fullUrl} className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                    <img id={o.fullUrl} style={{ height: height, width: width }} className='property-img ' alt='thumb' src={`${o.thumbnailUrl}`} ></img>
                                </div>
                            )) :
                            <div>
                            </div>
                    }
            </Carousel>
        
            {/* <div id={id} className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {
                        fullUrl ? images !== undefined ? images.map((o, index) => (
                            <div key={o.fullUrl} className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                <img id={o.fullUrl} style={{ height: height, width: width }} className='property-img ' alt='thumb' src={`${o.fullUrl}`} ></img>
                            </div>
                        )) :
                            <div>
                            </div> : images !== undefined ? images.map((o, index) => (
                                <div key={o.fullUrl} className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                    <img id={o.fullUrl} style={{ height: height, width: width }} className='property-img ' alt='thumb' src={`${o.thumbnailUrl}`} ></img>
                                </div>
                            )) :
                            <div>
                            </div>
                    }
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target={"#" + id} data-bs-slide="prev">
                    <ArrowBackIosNewRoundedIcon sx={{ fontSize: 50, color: 'black' }}></ArrowBackIosNewRoundedIcon>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target={"#" + id} data-bs-slide="next">
                    <ArrowForwardIosRoundedIcon sx={{ fontSize: 50, color: 'black' }}></ArrowForwardIosRoundedIcon>
                </button>
            </div> */}
        </>
    );
}