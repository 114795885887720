class SelectorModel {

   constructor(id, value){
    this.id = id;
    this.value = value;
   }

}


export default SelectorModel;