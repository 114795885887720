import '../components/Header'
import Header from '../components/Header';
import * as React from 'react';
import '../styles/css/main.css'

import axios from 'axios';
import PropertyModel from '../data/models/property';
import Footer from '../components/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import CardSwiper from '../components/CardSwiper';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { AppBar, Button, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import PanoramaIcon from '@mui/icons-material/Panorama';
import VideocamIcon from '@mui/icons-material/Videocam';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonGroup } from '@mui/material';
import Contact from '../components/Contact';
import MapContainer from '../components/Map';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import Config from '../config';
import { useEffect, useState } from 'react';
import googleLogo from '../images/Google-Translate-Logo.png';
import { NumericFormat } from 'react-number-format';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PropertyDetail() {
  const { t } = useTranslation();
  const params = useParams("id");

  const [property, setProperty] = React.useState(new PropertyModel());
  const [open, setOpen] = React.useState(false);

  const [openContact, setOpenContact] = React.useState(false);
  const [openMap, setOpenMap] = React.useState(false);
  const [title, setTitle] = useState(property.name);
  const [descp, setDescp] = useState(property.description);
  React.useEffect(() => {

    setOpenContact(false);
    const headers = {
      "content-type": "application/json",
    };
    const lang = i18next.language;
    let url = Config.apiUrl + 'properties/' + params.id + '?languageCode=' + lang;

    axios.get(url, { headers })
      .then(response => {
        var property = response.data.data;
        setProperty(property);
        setMetaTags(property);
        if (property.images && property.images.length > 0) {
          property.images.sort((a, b) => a.order - b.order);
        }
        url = `https://translation.googleapis.com/language/translate/v2?key=${Config.apiKeyGoogleMaps}`;
        url += '&q=' + encodeURI(property.name);
        url += `&target=${i18next.language}`;
        fetch(url)
          .then(response => response.json())
          .then(data => {
            setTitle(data.data.translations[0].translatedText);
          });

        url = `https://translation.googleapis.com/language/translate/v2?key=${Config.apiKeyGoogleMaps}`;
        url += '&q=' + encodeURI(property.description);
        url += `&target=${i18next.language}`;
        fetch(url)
          .then(response => response.json())
          .then(data => {
            setDescp(data.data.translations[0].translatedText);
            setMetaTags({ ...property, description: data.data.translations[0].translatedText });
          })
      });

  }, [])

  const navigate = useNavigate();


  const handleClickOpen = () => {
    setOpen(true);
  };



  const handleClose = () => {
    setOpen(false);
  };

  function setMetaTags(property) {
    const title = property.name;
    const description = property.description;
  
    function updateMetaTag(property, content) {
      let meta = document.querySelector(`meta[property="${property}"]`);
      if (!meta) {
        meta = document.createElement('meta');
        meta.setAttribute('property', property);
        document.head.appendChild(meta);
      }
      meta.setAttribute('content', content);
    }
  
    updateMetaTag('og:title', title);
  
    updateMetaTag('og:description', description);
  
    if (property.images && property.images.length > 0) {
      const thumb = property.images[0].thumbnailUrl;
      updateMetaTag('og:image', thumb);
    } else {
      const ogImage = document.querySelector('meta[property="og:image"]');
      if (ogImage) {
        ogImage.remove();
      }
    }
  }
  
  function goToStorePage() {
    navigate(process.env.PUBLIC_URL + '/storePage/');
  }

  function openContactDialog() {
    setOpenContact(true);
  }

  function closeContactDialog() {
    setOpenContact(false);
  }

  function showMap() {
    setOpenMap(true);
  }

  function hideMap() {
    setOpenMap(false);
  }

  return (
    <>
      <Dialog
       key={"dialog-1"}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>

            <Typography sx={{ ml: 2, flex: 1 }} style={{ color: 'black' }} variant="h6" component="div">
              {property.name}
            </Typography>

            <Button style={{ marginRight: 10 }} onClick={goToStorePage} variant="outlined"><FavoriteIcon></FavoriteIcon> &nbsp; {t('saveFavorite')}</Button>
            <Button style={{ marginRight: 10 }} onClick={openContactDialog} variant="contained">{t('contact')}</Button>

            <IconButton
              edge="end"
              color="black"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className='center'>
          <Dialog className='contact-dialog'
            open={openContact}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeContactDialog}
            aria-describedby="alert-dialog-slide-description">

            <DialogContent>
              <Contact property={property}></Contact>
            </DialogContent>
          </Dialog>
          <CardSwiper key= {"carousel_" + property.id} height={"90vh"} fullUrl={true} id={"carousel_" + property.id} images={property.images}></CardSwiper>
        </div>
      </Dialog>

      <Dialog
    key={"dialog-2"}
        fullScreen
        open={openMap}
        onClose={hideMap}
        TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} style={{ color: 'black' }} variant="h6" component="div">
              {property.title}
            </Typography>

            <Button style={{ marginRight: 10 }} onClick={goToStorePage} variant="outlined"><FavoriteIcon></FavoriteIcon> &nbsp; {t('saveFavorite')}</Button>
            <Button style={{ marginRight: 10 }} onClick={openContactDialog} variant="contained">{t('contact')}</Button>

            <IconButton
              edge="end"
              color="black"
              onClick={hideMap}
              aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className='center'>

          <Dialog className='contact-dialog'
           key={"dialog-3"}
            open={openContact}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeContactDialog}
            aria-describedby="alert-dialog-slide-description">
            <DialogContent>
              <Contact property={property}></Contact>
            </DialogContent>
          </Dialog>

          <MapContainer  key={"map-detail"} lat={property.latitude}  lng={property.longitude}  markers={[{lng: property.longitude, lat: property.latitude}]} ></MapContainer>
        </div>

      </Dialog>

      <div className='row mb-3 ' style={{marginTop: 20}}>

        <div className='col-md-8'>
          <div className='detail-container'>
            <div className='center'>
              {property.images ? <img style={{ width: "100%" }} src={property.images[0].thumbnailUrl}></img> : <img src=''></img>}
            </div>

            <div className='row mb-3 mt-3'>
              <div>
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                  {property.images ? <Button onClick={handleClickOpen}><PanoramaIcon></PanoramaIcon> &nbsp; {property.images.length} {t('photos')}</Button> : <div></div>}
                  <Button><VideocamIcon></VideocamIcon> &nbsp; Video</Button>
                </ButtonGroup>
              </div>
            </div>

            <div className='row mt-3'>
              <h3 style={{ fontWeight: 'bold' }}>{property.name}</h3>
            </div>

            <div className='row mb-3'>
              <span>{property.address}  <a style={{ color: 'black' }} href='#' onClick={showMap}>  <LocationOnIcon></LocationOnIcon>{t('viewMap')}</a> </span>
            </div>

            <div className='row mb-3'>
              <span>{property.distrito} {property.distrito != "" && property.distrito != null && property.municipio != "" ? ', ' + property.municipio : property.municipio}
                {property.provincia != "" && property.municipio != "" ? ', ' + property.provincia : property.provincia}</span>
            </div>
           
            <div className='row mb-3'>
              <strong style={{ fontSize: 30 }}>{new Intl.NumberFormat('en-US', {  currency: 'USD' }).format(property.price)}</strong>
              
            </div>

            <div className='row mb-3'>
              <span >{property.m2} m² | {property.rooms} {t('room')} {property.state ? '| ' + property.state : ""} </span>
            </div>

            <div className='row mb-3'>
              <div>
                <Button onClick={goToStorePage} variant="outlined"><FavoriteIcon></FavoriteIcon> &nbsp; {t('saveFavorite')}</Button>
              </div>
            </div>

            <div className='row mb-3'>
              <h4><strong>{t('description')}</strong></h4>
              <p>{descp}</p>

              {i18next.language == "en" ?
                <div className="translationWarning">
                  <span>
                    This comment was automatically translated using Google Translate, and may not be 100% accurate.
                  </span>
                  <span className="viewOriginalComment">

                  </span>
                  <div className="googleTranslateLogo">
                    <img src={googleLogo} />
                  </div>
                </div>
                : ""}
            </div>

            {property.features ?
              <>
                <hr className="featurette-divider"></hr>
                <div className='row mb-3'>
                  <h4><strong>{t('basicfeatures')}</strong></h4>
                  <ul style={{ marginLeft: 20 }}>{property.features.map(f => (
                    <li>
                      {f.name}
                    </li>
                  ))}</ul>
                </div>
              </>
              : <div></div>}
          {property.images ? (
            <div className='row'>
              {property.images.map((i) => (
                <div
                  className='img-container mb-3'
                  key={i.fullUrl}
                  ref={el => {
                    if (el) {
                      const imgElement = el.querySelector('img');
                      imgElement.onload = () => {
                        const naturalHeight = imgElement.naturalHeight;
                        const naturalWidth = imgElement.naturalWidth;
                        
                        const isVertical = naturalHeight > naturalWidth;
                        
                        imgElement.style.objectFit = isVertical ? 'contain' : 'cover';

                        imgElement.style.maxHeight = '600px';
                      };
                    }
                  }}
                >
                  <img src={i.fullUrl} alt={i.altText} className='img-detail' />
                </div>
              ))}
            </div>
          ) : null}
          </div>
        </div>
        <div className='col-md-4'>
          <Contact property={property}></Contact>
        </div>
      </div>
  
    </>
  );
}