import '../styles/css/listing.css'
import axios, { isCancel, AxiosError } from 'axios';
import PropertyModel from '../data/models/property';
import React from 'react';
import { FormControlLabel, RadioGroup, Radio } from '@mui/material';
import i18next from '../services/i18n'
import { useTranslation } from "react-i18next";
import Config from '../config';


export default function PublicationDateSelector({ filters ,setPageCount, itemsPerPage, setTotalProperties, setCurrentIndex, setFilters, setProperties, setIsLoading }) {
  const { t, i18next } = useTranslation();

  function onChange(days) {
    setCurrentIndex(1);
    filters.From = 0;
    filters.MaxPostAgeInDays = days;

    setFilters(filters);

    setIsLoading(true);
    const headers = {
      "content-type": "application/json",
    };

    axios.post(Config.apiUrl + 'properties/search', filters, { headers })
      .then(response => {
        var propes = response.data.data.properties;
        var result = propes.map(p => new PropertyModel(p.id, p.name, p.price, p.rooms, p.m2, p.description, p.isFavourited, p.features, p.images));
      
        setIsLoading(false);

        var total = 0;
        if(propes.length > 0){
          total = response.data.data.properties[0].total;
        }
        setTotalProperties(total);
        setPageCount(Math.ceil(total / itemsPerPage));
        setProperties(result);

        var jsonFilters = JSON.stringify(filters);
        localStorage.setItem("filters", jsonFilters);
      });
  }
  return (
    <>

      <div className="input-group mb-3">
        <label>{t('publicationDate')}:</label>

        <div className='row'>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="indiferente"
            name="radio-buttons-group">

            <FormControlLabel value="indiferente" control={<Radio onClick={e => onChange(null)}
              sx={{
                '&.Mui-checked': {
                  color: '#F65C01',
                },
              }} />} label={t('indifferent')} />


            <FormControlLabel value="lastmonth" control={<Radio onClick={e => onChange(30)}
              sx={{
                '&.Mui-checked': {
                  color: '#F65C01',
                },
              }} />} label={t('lastmonth')} />



            <FormControlLabel value="lastweek" control={<Radio onClick={e => onChange(7)}
              sx={{
                '&.Mui-checked': {
                  color: '#F65C01',
                },
              }} />} label={t('lastweek')} />


            <FormControlLabel value="last48h" control={<Radio onClick={e => onChange(2)}
              sx={{
                '&.Mui-checked': {
                  color: '#F65C01',
                },
              }} />} label={t('last48h')} />

          </RadioGroup>
        </div>
      </div>
    </>
  );
}