import '../styles/css/listing.css';
import { Checkbox } from '@mui/material';
import { useEffect } from 'react';
import axios from 'axios';
import PropertyModel from '../data/models/property';
import React from 'react';
import { useTranslation } from "react-i18next";
import Config from '../config';

export default function FeaturesChecks({ features, filters, setPageCount, itemsPerPage, setTotalProperties, setCurrentIndex, setFilters, setProperties, setIsLoading }) {
  const { t } = useTranslation();

  useEffect(() => {
    function searchProperties() {
      setIsLoading(true);
      const headers = {
        "content-type": "application/json",
      };
      axios.post(Config.apiUrl + 'properties/search', filters, { headers })
        .then(response => {
          var propes = response.data.data.properties;
          var result = propes.map(p => new PropertyModel(p.id, p.name, p.price, p.rooms, p.m2, p.description, p.isFavourited, p.features, p.images));

          setIsLoading(false);

          var total = 0;
          if (propes.length > 0) {
            total = response.data.data.properties[0].total;
          }

          setTotalProperties(total);
          setPageCount(Math.ceil(total / itemsPerPage));
          setProperties(result);
          var jsonFilters = JSON.stringify(filters);
          localStorage.setItem("filters", jsonFilters);
        })
        .catch(error => {
          setIsLoading(false);
          console.error('Error searching properties:', error);
        });
    }

    searchProperties();
  }, [filters, setPageCount, itemsPerPage, setTotalProperties, setProperties, setIsLoading]);

  function onChangeFeatures(id, checked) {
    setCurrentIndex(1);
    filters.From = 0;
    if (checked) {
      filters.Features.push(id);
    } else {
      const index = filters.Features.indexOf(id);
      if (index !== -1) {
        filters.Features.splice(index, 1);
      }
    }

    setFilters({...filters});

  }

  if (features.length === 0) {
    return null;
  }

  return (
    <div className="input-group mb-3">
      <label>{t('features')}:</label>
      <div className='row'>
        {features.map(f => (
          <div key={f.id} className="form-check" style={{ paddingLeft: 0 }}>
            <Checkbox
              key={f.id}
              onChange={e => onChangeFeatures(f.id, e.target.checked)}
              checked={filters.Features.includes(f.id)}
              sx={{
                color: "#F65C01",
                '&.Mui-checked': {
                  color: "#F65C01",
                },
              }}
            />
            <label className="form-check-label" htmlFor="flexCheckChecked">
              {f.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}