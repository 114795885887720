import instagram_icon from '../images/instagram2.png';
import facebook_icon from  '../images/facebook2.png';
import background from  '../images/footer-bg.png';
import logo from '../images/logo_footer.png';
import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t, i18next } = useTranslation();
  
    return (
      <div style={{ backgroundColor: '#1F3465' }}>
           <div className='FooterCK main-box' >
       
        <div className='row'>
            <div className='col-md-6' >
                  <a href="/" rel="noreferrer"><img src={logo} className="Footer-logo" alt="logo" /></a>
            </div>
          
            <div className='col-md-6 end ' >
              <div className='Socials end mb-2' >
                  <div className='Socials-container'>
                    <a href="https://instagram.com" rel="noreferrer">
                      <img src={instagram_icon} className="Social-icon" alt="Instagram" style={{marginRight: 10}} />
                    </a>
                    <a href="https://facebook.com" rel="noreferrer">
                      <img src={facebook_icon}  className="Social-icon" alt="Facebook"/>
                    </a>
                  </div>
              </div>
             
              <div className='Socials mb-2 end' >
                <span className='fotter-heading' style={{marginRight: 20}}>(+1) 829 723 1009</span>
              </div>
              <div className='Socials  mb-2 end' >
                <span className='fotter-heading' style={{marginRight: 20}}>info@clickclickrd.com</span>
              </div>
            </div>
        </div>
      </div>
      </div>
   
    );
  }