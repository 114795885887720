class PropertyModel {

    constructor(id,name,price,rooms,m2, description,isFavourited,features, images, latitude, longitude, aprobado,
        address,
        type,
        operationType,
        phone,
        agency,
        municipio,
        district,provincia, contact){
            this.id = id;
            this.name = name;
            this.price = price;
            this.rooms = rooms;
            this.description = description;
            this.m2 = m2;
            this.isFavourited = isFavourited;
            this.features = features;
            this.images = images;
            this.latitude = latitude;
            this.longitude = longitude;
            this.aprobado = aprobado;
            this.address = address;
            this.type = type;
            this.operationType = operationType;
            this.phone = phone;
            this.agency = agency;
            this.municipio = municipio;
            this.district = district;
            this.province = provincia;
            this.contact = contact;
        }
 
 }
 
 
 export default PropertyModel;