import appStore from "../images/app-store.png"
import googlePlay from "../images/google-play.png"
import background from "../images/clickclick_iphone.png"
import React from 'react';
import { useTranslation } from "react-i18next";
import Config from "../config";

export default function PrivacyPolicyPage() {
    const { t } = useTranslation();
    return (
        <>
            <div className="position-relative overflow-hidden" >
                <div className="container h-100 privacy-policy">
                    <h3 className="mb-4">Política de privacidad</h3>
                    <p>Fecha última actualización: 20/10 /2023 </p>
                    <p>De conformidad con el Reglamento (UE) 2016/679, del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (Reglamento General de Protección de Datos - RGPD), REPUBLICLICK DOMINICANA, S.R.L. informa a los usuarios de la aplicación CLICK CLIK RD (en adelante, la Aplicación), acerca del tratamiento de los datos personales, que ellos voluntariamente hayan facilitado durante el proceso de registro, acceso y utilización del servicio.</p>

                    <strong>1. IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO. </strong>

                    <p>REPUBLICLICK DOMINICANA, S.R.L., con CIF 1-32-69964-5 y domicilio a efectos de notificaciones en: Jumbo Downtown Mall, Local 4 Nivel 2, Av. Barceló, Punta Cana (en adelante, el Responsable del Tratamiento), es la entidad responsable del tratamiento de los datos facilitados por los usuarios de la Aplicación (en adelante, el/los Usuario/s). Será Responsable del tratamiento de los datos que sean necesarios para realizar las gestiones necesarias para prestar el servicio y la gestión y mejora de la aplicación, así como si se dispone del consentimiento realizar envíos de comunicaciones comerciales.</p>
                
                    <strong>2. FINALIDAD DEL TRATAMIENTO DE DATOS. </strong>

                    <strong>2.1 Los datos para proceder al registro, acceso y posterior uso de la Aplicación</strong>

                    <p>El Usuario podrá ser:</p>

                    <ul>
                        <li><strong>Usuario final:</strong> en este caso el registro es voluntario. En caso de acceder voluntariamente al registro se deben facilitar únicamente el email y el nombre. Si el usuario quiere publicar un piso también deberá poner el teléfono. Puede publicar hasta dos anuncios gratuitos, a partir del tercero en adelante los deberá pagar. De manera opcional puede poner una imagen de perfil.</li>
                        <li><strong>Usuario profesional:</strong>el registro es obligatorio y deberá facilitar de forma voluntaria el nombre de la empresa el email y el teléfono. Para poder realizar una publicación el usuario profesional puede seleccionar packs de anuncios.</li>
                    </ul>
                   
                    <p>Ambos Usuarios (finales y profesionales) podrán subir fotografías de las viviendas en cuestión y de la ubicación de las mismas, no suponiendo el tratamiento de ningún dato personal.</p>
                    <p>Los precios y las tarifas para publicar anuncios como usuario final o profesional están disponibles en el contacto <a href="mailto:broker@clickclickrd.com">broker@clickclickrd.com</a> </p>

                    <strong>2.2 Finalidades y tratamiento de los datos</strong>
                    <p>Todos los datos personales serán incorporados en soportes automatizados titularidad de REPUBLICLICK DOMINICANA, S.R.L. contando con las medidas técnicas y organizativas adecuadas para garantizar un nivel óptimo de seguridad. La recogida, almacenamiento, modificación, estructuración y en su caso, eliminación, de los datos proporcionados por los Usuarios, constituirán operaciones de tratamiento llevadas a cabo por el Responsable, con la finalidad de:</p>

                    <ul>
                        <li>Garantizar el correcto funcionamiento de la Aplicación, </li>
                        <li>Mantener la relación de prestación de servicios con el Usuario para la gestión, administración, información, prestación y mejora del servicio</li>
                        <li>Si el Usuario así lo ha consentido, los datos personales facilitados por el Usuario - especialmente, el correo electrónico - podrán emplearse también para remitir boletines (newsletter), así como comunicaciones comerciales de promociones y/o publicidad de la Aplicación. </li> 
                        <li>Si el Usuario así lo ha requerido, los datos personales facilitados por el Usuario se podrán usar para dar respuesta a las consultas planteadas mediante el formulario de contacto. </li>  
                        <li>Si el Usuario así lo ha requerido, los datos personales facilitados por el Usuario se podrán usar para dar respuesta a las consultas planteadas mediante el chat. </li>
                    </ul>
                
                    <strong>2.3 Información sobre el acceso a recursos o funciones del dispositivo</strong>

                    <p>Para acceder a ciertas funcionalidades de la Aplicación, se le puede solicitar su permiso para:</p>
                    
                    <ul>
                        <li>Poner la imagen del usuario en su perfil. Si quiere subir una fotografía, la Aplicación le pedirá su permiso para acceder a la cámara y a su galería de imágenes.</li>
                        <li>Publicar imágenes sobre los inmuebles que anuncia. Si quiere subir una fotografía de los inmuebles que anuncia, la Aplicación le pedirá su permiso para acceder a la cámara y a su galería de imágenes.</li>
                        <li>Subir documentación sobre los inmuebles que anuncia. Si quiere subir documentación sobre los inmuebles que anuncia, la Aplicación le pedirá su permiso para acceder a sus documentos.</li>
                    </ul>

                    <p>Siempre puede revocar su permiso desde los ajustes de su dispositivo. </p>

                    <strong>3. LEGITIMACIÓN. </strong>
                    <p>El tratamiento de los datos del Usuario, se realiza con base en el consentimiento libre, específico, informado e inequívoco del Usuario, puesto a su disposición mediante la presente política de privacidad, que deberá aceptar a través de una clara acción afirmativa, marcando la casilla correspondiente. En caso de que el Usuario no facilite a REPUBLICLICK DOMINICANA, S.R.L. sus datos, o lo haga de forma errónea o incompleta, no será posible proceder al uso de la Aplicación. Esta base jurídica legitima el tratamiento de los datos. Previa a la solicitud de información y/o la contratación de los servicios de la Aplicación, se pondrán a disposición del usuario los términos y condiciones que rigen esta Aplicación.  </p>
                    
                    <strong>5. DESTINATARIOS. </strong>
                    <p>Los datos no se comunicarán a ningún tercero ajeno a REPUBLICLICK DOMINICANA, S.R.L., salvo por obligación legal o, en cualquier caso, previa solicitud del consentimiento del Usuario. De otra parte, REPUBLICLICK DOMINICANA, S.R.L. podrá dar acceso o transmitir los datos personales facilitados por el Usuario, a terceros proveedores de servicios, con los que haya suscrito acuerdos de encargo de tratamiento de datos, y que únicamente accedan a dicha información para prestar un servicio en favor y por cuenta del Responsable. La información se aloja en Amazon Web Services en EEUU, a cuya política de privacidad puede acceder haciendo clic <strong><a href="https://aws.amazon.com/es/compliance/data-privacy/">aquí</a></strong>.</p>
                    
                    <strong>6. RETENCIÓN DE DATOS. </strong>
                    <p>REPUBLICLICK DOMINICANA, S.R.L. informa al Usuario que, como prestador de servicio de alojamiento de datos, retiene por un período máximo de 12 meses la información imprescindible para identificar el origen de los datos alojados y el momento en que se inició la prestación del servicio. La retención de estos datos no afecta al secreto de las comunicaciones y sólo podrán ser utilizados en el marco de una investigación criminal o para la salvaguardia de la seguridad pública, poniéndose a disposición de los jueces y/o tribunales o del Ministerio que así los requiera. La comunicación de datos a las Fuerzas y Cuerpos de Seguridad del Estado, se hará en virtud de lo dispuesto por la normativa sobre protección de datos personales, y bajo el máximo respeto a la misma. </p>
                    
                    <strong>7. PROTECCIÓN DE LA INFORMACIÓN. </strong>
                    <p>El Responsable del Tratamiento adopta las medidas necesarias para garantizar la seguridad, integridad y confidencialidad de los datos conforme a lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de los mismos. Si bien el Responsable, realiza copias de seguridad de los contenidos alojados en sus servidores, sin embargo, no se responsabiliza de la pérdida o el borrado accidental de los datos por parte de los Usuarios. De igual manera, no garantiza la reposición total de los datos borrados por los Usuarios, ya que los citados datos podrían haber sido suprimidos y/o modificados durante el periodo de tiempo transcurrido desde la última copia de seguridad. Los servicios facilitados o prestados a través de la Aplicación, excepto los servicios específicos de backup, no incluyen la reposición de los contenidos conservados en las copias de seguridad realizadas por el Responsable del Tratamiento, cuando esta pérdida sea imputable al Usuario; en este caso, se determinará una tarifa acorde a la complejidad y volumen de la recuperación, siempre previa aceptación del Usuario. La reposición de datos borrados solo está incluida en el precio del servicio cuando la pérdida del contenido sea debida a causas atribuibles al Responsable. </p>

                    <strong>8. DERECHOS DE LOS USUARIOS. </strong>
                    <p>REPUBLICLICK DOMINICANA, S.R.L. informa al Usuario que tiene a su disposición los derechos de acceso, rectificación, limitación, supresión, oposición y portabilidad, los cuales podrá ejercer mediante petición dirigida al correo electrónico: <a href="mailto:info@clickclickrd.com">info@clickclickrd.com</a>. Asimismo, el Usuario tiene derecho a revocar el consentimiento inicialmente prestado, y a interponer reclamaciones de derechos frente a la Agencia Española de Protección de Datos (www.aepd.es). </p>

                    <strong>9. COMUNICACIONES COMERCIALES POR VÍA ELECTRÓNICA. </strong>
                    <p>REPUBLICLICK DOMINICANA, S.R.L. no enviará comunicaciones publicitarias o promocionales por correo electrónico u otro medio de comunicación electrónica equivalente que previamente no hubieran sido solicitadas o expresamente autorizadas por los destinatarios de las mismas. En el caso de Usuarios con los que exista una relación contractual, jurídica o de servicios previa, el Responsable del Tratamiento, sí está autorizado al envío de comunicaciones comerciales referentes a productos o servicios del Responsable que sean similares a los que inicialmente fueron objeto de contratación con el usuario. En caso de que el Usuario quiera darse de baja a la hora de recibir las citadas comunicaciones, podrá hacerlo remitiendo su voluntad por e-mail al correo electrónico: <a href="mailto:info@clickclickrd.com">info@clickclickrd.com</a></p>
                    
                </div>
            </div>
        </>
    );
}