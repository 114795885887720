import '../styles/css/listing.css'
import CardSwiper from './CardSwiper';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import i18next from '../services/i18n';
import { useTranslation } from "react-i18next";
import Config from '../config';
import { AppBar, Button, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import Contact from '../components/Contact';
import { NumericFormat } from 'react-number-format';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function PropertyCard({ property }) {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [title, setTitle] = useState(property.name);
    const [openContact, setOpenContact] = React.useState(false);

    function detail(e) {
        // e.preventDefault();
        // navigate({
        //     pathname: '/detail',
        //     search: '?id=' + property.id,
        //   });
        navigate(process.env.PUBLIC_URL + '/details/' + property.id);
    }

    function goToStorePage(e) {
        e.stopPropagation()
        navigate(process.env.PUBLIC_URL + '/storePage/');
    }


    function call(e) {
        e.stopPropagation()
        //  window.location.href = "tel:" + property.phone;
    }

    function contact(e) {
        e.stopPropagation()
        //  window.location.href = "tel:" + property.phone;
        setOpenContact(true);
    }
    function closeContactDialog() {
        setOpenContact(false);
    }

    useEffect(() => {
        let url = `https://translation.googleapis.com/language/translate/v2?key=${Config.apiKeyGoogleMaps}`;
        url += '&q=' + encodeURI(property.name);
        url += `&target=${i18next.language}`;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setTitle(data.data.translations[0].translatedText);
            })
    }, [])

    return (
        <>
            <Dialog className='contact-dialog'
                open={openContact}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeContactDialog}
                aria-describedby="alert-dialog-slide-description">

                <DialogContent>
                    <Contact property={property}></Contact>
                </DialogContent>

            </Dialog>
            <div className='row property-container' id={`${property.id}`} onClick={e => detail(e)} >
                {/* <CardSwiper  key={property.id} images={property.images} id={"carousel_" + property.id} height={265} width={300}></CardSwiper> */}
                <div className="col-md-4 col-sm-12 " style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <img src={property.images[0].thumbnailUrl} height={265} width={300}></img>
                </div>

                <div className='info-container col-md-7 col-sm-12' style={{ backgroundColor: 'white' }}>
                    <div className='row'>
                        <a href='#'><p>{title}</p></a>
                        {/* <p className='price'>${property.price}</p> */}
                        <NumericFormat className='price' value={property.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        <p>{property.rooms} {t('room')} {property.m2} m²</p>
                        <p>{property.description}</p>
                        <div className='btns row'>
                            <div className='col-3'>
                                <a onClick={call} href={"tel:" + property.phone} className='btn'><span>{t('call')}</span></a>
                            </div>
                            <div className='col-3'>
                                <a onClick={contact} href='#' className='btn'><span>{t('contact')}</span></a>
                            </div>
                            <div className='col-6' style={{ textAlign: 'end' }}>
                                <button onClick={e => goToStorePage(e)} className='btn'><span><FavoriteBorderIcon></FavoriteBorderIcon></span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}