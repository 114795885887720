import React, { Component } from 'react';
import { GoogleMap, LoadScript, MarkerF} from '@react-google-maps/api';
import Config from '../config';

const containerStyle = {
  width: "100%",
  height: "600px"
};

const center = {
  lng: -68.4713458, lat: 18.6407175
};

class MapNew extends Component {
  render() {
    return (
      window.google === undefined ?  <LoadScript
      googleMapsApiKey={Config.apiKeyGoogleMaps}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10} >
        {this.props.markers.map(m => (
          <MarkerF key={m.id} onClick={this.onMarkerClick}
            position={{ lat: m.lat, lng: m.lng }}
          />
        ))}
      </GoogleMap>
    </LoadScript> :
     
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
        >
          {this.props.markers.map(m => (
            <MarkerF key={m.id} onClick={this.onMarkerClick}
              position={{ lat: m.lat, lng: m.lng }}
            />
          ))}
        </GoogleMap>
    )
  }
}


export default MapNew;
