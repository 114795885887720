import instagram_icon from '../images/instagram.png';
import facebook_icon from '../images/facebook.png';
import youtube_icon from '../images/youtube.png';
import logo from '../logo.png';
import React, { useEffect, useState } from 'react';
import { MenuItem, OutlinedInput, Select } from '@mui/material';
import Address from '../data/models/address';
import { FormControl } from 'react-bootstrap';
import i18next from 'i18next';



export default function Header() {

  function onChangeLanguage(value) {
    localStorage.setItem("lang", value);
    // i18next.changeLanguage(value)
    window.location.reload();
  }

  const languages = [{ value: 'es', label: "Español" }, { value: 'en', label: "English" }];
  const [lang, setLang] = React.useState(i18next.language);

  useEffect(() => {


  })

  return (
    <div style={{ backgroundColor: '#F2EDDA' }}>
      <div className='Header main-box' >

        <a href="/" rel="noreferrer">
          <img src={logo} className="App-logo" alt="logo" />
        </a>
        <div className='Socials'>
          <Select className='lang' 
            value={lang}
            onChange={e => onChangeLanguage(e.target.value)} >
            <MenuItem value="es">
              Español
            </MenuItem>

            <MenuItem value="en">
              English
            </MenuItem>

          </Select>
          <div className='Socials-container'>
            <a href="https://facebook.com" rel="noreferrer">
              <img src={facebook_icon} className="Social-icon" alt="Facebook" style={{ marginRight: 10 }} />
            </a>
            <a href="https://instagram.com" rel="noreferrer">
              <img src={instagram_icon} className="Social-icon" alt="Instagram" />
            </a>
          </div>
        </div>



      </div>
    </div>

  );
}